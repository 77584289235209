// getMeData.js
'use strict';
import { Collection, List } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';
import { format } from 'date-fns';
import { getMaskedUsername } from '../resource/getMaskedUsername.js';
import { getHashColor } from '../resource/getHashColor.js';
import { PaymentMethodUsage } from '../resource/shopConstants.js';

/**
 * Select me data by data key
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} dataKey - select key.
 * @return {any} The selected me data.
 */
const getMeData = createCachedSelector(
  state => state.get('me'),
  (state, dataKey) => dataKey,
  (me, dataKey) => {
    let result = undefined;
    if (me) {
      result = me.get(dataKey);
      if ('biography' === dataKey) {
        result = me.get(dataKey) || '';
      }
      if ('hasBalance' === dataKey) {
        const balance = me.getIn(['balance', 'total']);
        result = balance != null && !Number.isNaN(balance);
      }
      if ('settingsPreferences' === dataKey) {
        result = me.getIn(['settings', 'preferences']);
      }
      if ('unreadCount' === dataKey) {
        result = me.getIn(['chatroom', 'unreadCount']);
      }
      if ('hasUnread' === dataKey) {
        const unreadCount = me.getIn(['chatroom', 'unreadCount']) || 0;
        result = unreadCount > 0;
      }
      if ('notificationUnreadCount' === dataKey) {
        result = me.getIn(['notification', 'unreadCount']) || 0;
      }
      if ('hasUnreadNotification' === dataKey) {
        const unreadCount = me.getIn(['notification', 'unreadCount']) || 0;
        result = unreadCount > 0;
      }

      if ('tokenJti' === dataKey) {
        result = me.getIn(['jwtData', 'jti']);
      }
      if ('tokenSub' === dataKey) {
        result = me.getIn(['jwtData', 'sub']);
      }
      if ('tokenScopes' === dataKey) {
        result = me.getIn(['jwtData', 'scopes']);
      }

      if ('closeDownloadSchatModalTimestamp' === dataKey) {
        result = me.get('closeDownloadSchatModalTimestamp') || 0;
      }
      if ('closeEmailBindingAlertTimestamp' === dataKey) {
        result = me.get('closeEmailBindingAlertTimestamp') || 0;
      }
      if ('settingNotification' === dataKey) {
        result = me.getIn(['settings', 'notifications']);
      }
      if ('abTestLoading' === dataKey) {
        result = me.getIn(['ab', 'loading']);
      }
      if ('email' === dataKey) {
        result = me.getIn(['accountLinks', 'email', 'id']);
      }
      if ('isEmailBound' === dataKey) {
        result = !!me.getIn(['accountLinks', 'email', 'id']);
      }
      if ('isPasswordBound' === dataKey) {
        result = !!me.getIn(['accountLinks', 'password', 'id']);
      }
      if ('isTaiwan' === dataKey) {
        const country = me.getIn(['country']) || 'tw';
        result = country === 'tw';
      }
      if (dataKey === 'maskedUsername') {
        result = getMaskedUsername({ username: me.get('username') });
      }
      if (dataKey === 'avatarColor') {
        result = getHashColor({ hashStr: me.get('id') });
      }
      if (dataKey === 'referralCode') {
        result = me.getIn(['referral', 'code']);
      }
      if (dataKey === 'rewardReferral') {
        const rewardReferral =
          +me.getIn(['referral', 'reward', 'referral', 'value']) || null;
        result = rewardReferral;
      }
      if (dataKey === 'rewardReferrer') {
        const rewardReferrer =
          +me.getIn(['referral', 'reward', 'referrer', 'value']) || null;
        result = rewardReferrer;
      }
      if (dataKey === 'accumulatedRewards') {
        const accumulatedRewards = +me.getIn([
          'referral',
          'accumulated_rewards',
          'diamonds',
        ]);
        result = Number.isInteger(accumulatedRewards)
          ? accumulatedRewards
          : null;
      }
      if (dataKey === 'lastNotificationAskerShowTimestamp') {
        result =
          me.getIn(['me', 'notificationAsker', 'lastShowTimestamp']) || 0;
      }
      if (['contractId', 'contractUrl'].includes(dataKey)) {
        result = me.getIn(['contract', dataKey]);
      }
      if (dataKey === 'paymentMethods') {
        result = me.getIn(['paymentMethods', PaymentMethodUsage.ALL]);
      }
      if (dataKey === 'subscriptionPaymentMethods') {
        result = new List(
          me
            .getIn(['paymentMethods', PaymentMethodUsage.SUBSCRIPTION])
            ?.values()
        );
      }
      if (dataKey === 'replyPrice') {
        result = me.getIn(['settings', 'pricing', 'reply']);
      }
      if (dataKey === 'scorebookAward') {
        result = me.getIn([
          'scorebook',
          `${format(Date.now(), 'yyyy-MM')}`,
          'award',
        ]);
      }
      if (dataKey === 'isCheckedInDailyReward') {
        result = me.getIn(['dailyReward', 'isCheckedIn']);
      }
      return result instanceof Collection ? result.toJS() : result;
    }
  }
)((state, dataKey) => dataKey);

export default getMeData;

// mergeMeData.js
'use strict';
import { MERGE_ME_DATA } from '../ActionTypes.js';

const avaliableFields = [
  'token',
  'refreshToken',
  'defaultUsername',
  'fcmToken',
  'pushyToken',
  'biography',
  'birthdate',
  'gender',
  'currency',
  'usingList',
  'email',
  'orderEmail',
  'downloadMobileApp',
  'hasAgeVerified',
  'lastNotSupportAlertTimestamp',
  'accountLinks',
  'intercomUserHash',
  'closeDownloadSchatModalTimestamp',
  'closeEmailBindingAlertTimestamp',
  'utm',
  'clientId',
  'country',
  'defaultCountryCode',
  'regionGroup',
  'balance',
  'creator',
  'contract',
  'subscriptionPlan',
  'scorebook',
  'redirect',
  'lastFcmTokenTimestamp',
  'aquisitionAuthToken',
];

/**
 * Merge me data
 * @kind action
 * @param {string} {field} - me data field.
 * @param {any} {value} - value.
 * @return {Promise} Action promise.
 */
const mergeMeData =
  ({ field, value }) =>
  async dispatch => {
    if (!avaliableFields.includes(field)) {
      return dispatch({ type: '' });
    }
    return dispatch({ type: MERGE_ME_DATA, payload: { [field]: value } });
  };

export default mergeMeData;

// replaceTemplate.js
'use strict';

/**
 * Replace template
 * @param {string} string - template string.
 * @param {object} data - data object.
 * @return {string} return replaced string.
 */
const replaceTemplate = ({ string, data } = {}) => {
  if (!string || !data) {
    return string;
  }
  return string.replace(/{([^{}]+)}/gi, substring => {
    const key = substring.replace(/({|})/g, '');
    return data[key] || '';
  });
};

export default replaceTemplate;
